* {
  box-sizing: border-box;
}

body {
  background-image: url("fondo.c2865d7f.png");
  margin: 0;
}

/*# sourceMappingURL=index.806a27b2.css.map */
